<template>
<div class="pty-language-selector" v-show="visible">
    <vs-tooltip bottom shadow not-hover v-model="activeTooltip">
        <PtyButton  @click="activeTooltip = !activeTooltip">
                <i class="bx bx-globe"></i>
        </PtyButton>
        <template #tooltip>
            <PtySelector :options="languages" v-model="selectedLanguage" :value="selectedLanguage" colorizeMode="stroke" iconType="boxicon" block size="small" :showCheck="true" :gap="1" />    
        </template>
    </vs-tooltip>
    
</div>
</template>

<script>
import PtyButton from './PtyButton.vue';
import PtySelector from './PtySelector.vue';

export default {
    name: 'PtyLanguageSelector',
    components: {
        PtyButton,
        PtySelector
    },
    data: () => ({
        selectedLanguage: null,
        activeTooltip: false,
        languages: [{
            id: 'it',
            title: 'Italiano',
            icon: 'bx bx-flag',
            iconType: 'boxicon'
        }, {
            id: 'en',
            title: 'English',
            icon: 'bx bx-flag',
            iconType: 'boxicon'
        }]
    }),

    computed: {
        visible(){
            const routes = ['OnBoarding', 'Quiz', 'Booking', 'Reservation'];
            return routes.includes(this.$route.name);
        }
    },

    methods: {
        selectLanguage(){
            if(this.selectedLanguage){
                this.$emit('selected', this.selectedLanguage);
            }
        }
    },

    watch: {
        selectedLanguage(){
            // toggle i18n language
            this.$i18n.locale = this.selectedLanguage;
        }
    }
}
</script>

<style scoped>
.pty-language-selector {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}
</style>