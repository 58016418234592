/* eslint-disable */
import apiClient from '../client';
import {
    GetPutObjectSignedURL,
    GetGetObjectSignedURL,
    GetObjectResponse,
} from '../types';

const w = window as any;
const indexedDB = w.indexedDB || w.mozIndexedDB || w.webkitIndexedDB || w.msIndexedDB || w.shimIndexedDB;
let open : any = indexedDB.open('PTYFiles', 1);

open.onupgradeneeded = function() {
    // The database did not previously exist, so create object stores and indexes.
    const db = open.result;
    const store = db.createObjectStore('files', { keyPath: 'id' });
    store.createIndex('id', 'id', { unique: true });
    store.createIndex('url', 'url', { unique: false });
    store.createIndex('created_at', 'created_at', { unique: false });
};

const fileService = {
    getSignedUploadURL: async (params: GetPutObjectSignedURL): Promise<GetObjectResponse> => {
        const response = await apiClient.get<GetObjectResponse>('/files/getSignedUploadURL', { params });
        return response.data;
    },

    getSignedDownloadURL: async (params: GetGetObjectSignedURL): Promise<GetObjectResponse> => {
        // check if the file is stored locally
        const db = open.result;
        const txRead = db.transaction('files', 'readonly');
        const storeRead = txRead.objectStore('files');
        let localFile = await storeRead.get(params.name);

        const localResult :any = await new Promise((resolve) => {
            localFile.onsuccess = () => {
                resolve(localFile.result)
            }

            localFile.onerror = () => {
                resolve(null)
            }
        })

        console.log(localResult)

        if (localResult) {
            // check if the file is older than 24h
            const now = new Date();
            const createdAt = new Date(localResult.created_at);
            const diff = now.getTime() - createdAt.getTime();
            const diffHours = diff / (1000 * 3600);

            if(diffHours < 24) {
                console.log('returning local', localResult.url)
                return { url: localResult.url } as GetObjectResponse;
            } else {
                console.log('deleting local', localResult.url)
                const txWrite = db.transaction('files', 'readwrite');
                const store = txWrite.objectStore('files');
                await store.delete(params.name);
            }
        }


        const response = await apiClient.get<GetObjectResponse>('/files/getSignedDownloadURL', { params });

        if(response && response.data && response.data.url) {
            // store locally
            const txWrite = db.transaction('files', 'readwrite');
            const store = txWrite.objectStore('files');

            console.log('storing locally', response.data.url)
            await store.add({ id: params.name, url: response.data.url, created_at: new Date().toISOString() });

        }


        return response.data;
    },
};

export default fileService;
