/* eslint-disable */
import apiClient from '../client';
import {
    CreateQuizRequest,
    GetQuizzesRequest,
    EditQuizRequest,
    DeleteQuizRequest,
    GetQuizRequest,
    AnswerQuizRequest,
    CreateQuizResponse,
    GetQuizzesResponse,
    EditQuizResponse,
    GetQuizResponse,
} from '../types';

const quizService = {
    create: async (data: CreateQuizRequest): Promise<CreateQuizResponse> => {
        const response = await apiClient.post<CreateQuizResponse>('/quizzes', data);
        return response.data;
    },

    get: async (params?: GetQuizzesRequest): Promise<GetQuizzesResponse> => {
        const response = await apiClient.get<GetQuizzesResponse>('/quizzes', { params });
        return response.data;
    },

    update: async (id: string, data: EditQuizRequest): Promise<EditQuizResponse> => {
        const response = await apiClient.put<EditQuizResponse>(`/quizzes/${id}`, data);
        return response.data;
    },

    delete: async (params: DeleteQuizRequest): Promise<void> => {
        await apiClient.delete(`/quizzes/${params.id}`);
    },

    detail: async (params: GetQuizRequest): Promise<GetQuizResponse> => {
        const response = await apiClient.get<GetQuizResponse>(`/quizzes/${params.id}`);
        return response.data;
    },

    answer: async (data: AnswerQuizRequest): Promise<any> => {
        const response = await apiClient.post<any>(`/quizzes/${data.quiz_id}/answer`, data);
        return response;
    }
};

export default quizService;
