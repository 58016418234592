<template>
  <div id="app">
    <PtyLanguageSelector :languages="['it', 'en']" @selected="selectedLanguage = $event" />
    <transition name="slide" mode="out-in">
      <router-view :key="$route.fullPath" />
    </transition>
  </div>
</template>
<script>
import PtyLanguageSelector from './components/PtyLanguageSelector.vue';
import {chatService} from '@/rest';
import {GlobalEventEmitter} from './GlobalEventEmitter.js';
export default {
  name: 'App',
  data: () => ({
    ws: null,
    wsLoading: null,
  }),

  components: {
    PtyLanguageSelector
  },

  

  methods: {
    wsConnect(){
      console.log(this.ws);
      if(this.ws && (this.ws.readyState === WebSocket.OPEN || this.ws.readyState === WebSocket.CONNECTING)){
        console.log('already connecting/connected');
        return;
      }

      if(this.wsLoading){
        this.wsLoading.close();
      }

      this.wsLoading = this.$vs.loading({
        text: this.$t('Chats.connecting')
      });

      this.ws = chatService.connectToWebSocket();
      this.ws.onmessage = (message) => {
        GlobalEventEmitter.$emit('ws-message', message);
      },

      this.ws.onopen = () => {
        this.wsLoading.close();
        this.wsLoading = null;
      }

      // when disconnects, retry in 5s
      this.ws.onclose = () => {
        setTimeout(() => {
          this.wsLoading.close();
          if(!window.location.href.includes('localhost')){
            this.wsLoading = this.$vs.loading({
              text: this.$t('Chats.reconnecting')
            });
          }
        }, 1000);

        setTimeout(() => {
          this.wsConnect();
        }, 10000);
      }
    },

    wsDisconnect(){
      this.ws.close();
    },

    wsSend(d){
      this.ws.send(d);
    }
  },

  beforeDestroy() {
    GlobalEventEmitter.$off('ws-connect', this.wsConnect);
    GlobalEventEmitter.$off('ws-disconnect', this.wsDisconnect);
    GlobalEventEmitter.$off('ws-send', this.wsSend);
  },

  created() {
    GlobalEventEmitter.$on('ws-connect', this.wsConnect);
    GlobalEventEmitter.$on('ws-disconnect', this.wsDisconnect);
    GlobalEventEmitter.$on('ws-send', this.wsSend);
  }
}
</script>
<style>

#app {
  font-family: 'Sofia Pro', sans-serif;
}

#app, body, html {
  margin: 0;
  background: var(--Linear, linear-gradient(12deg, #00A9C0 0.95%, rgba(0, 169, 192, 0.63) 101.3%));
  overflow-y:hidden!important;
  overflow-x:hidden!important;
  height:100%;
}

.vs-notification__content {
  font-family: 'Sofia Pro', sans-serif!important;
}

/* 20px padding X, 10px padding Y */
#app > * {
  padding: 10px 20px!important;
  box-sizing: border-box;
  touch-action: manipulation;
  max-height: 100%;

}

/* fade vue transition */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.3s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}

.pty-divider {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1rem;
}

.pty-divider hr {
  flex-grow: 1;
  border: 0;
  height: 1px;
  background: #CDCDCD;
}

.pty-divider p {
  color: #CDCDCD;
  text-transform: uppercase;
  font-size: 0.8rem!important;
  font-weight:300!important;
}

.vs-avatar-content.history:after {
  background: rgba(var(--vs-primary))!important;
}

.inbox .vs-avatar-content--size.history:after{
  top:0!important;
}

.inbox .vs-avatar__badge {
    background: transparent;
    border: unset !important;
    left: -54px;
    bottom: -5px;
}
.inbox .vs-avatar__badge img {
    /* png shadow filter */
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.35));
    transform: rotate(15deg);
}

.slide-down {
  max-height: 200px;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.slide-down.collapsed {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.vs-loading__load__text {
  font-family: 'Sofia Pro', sans-serif;
  font-size: 1.2rem;
}

</style>
