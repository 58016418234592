/* eslint-disable */
import apiClient from '../client';
import {
    GetMatchesRequest,
    EditMatchRequest,
    GetMatchedRequest,
    GetMatchesResponse,
    GetMatchedResponse,
} from '../types';

const matchService = {
    get: async (params?: GetMatchesRequest): Promise<GetMatchesResponse> => {
        const response = await apiClient.get<GetMatchesResponse>('/matches', { params });
        return response.data;
    },

    edit: async (data: EditMatchRequest): Promise<any> => {
        const response = await apiClient.patch<any>(`/matches/${data.matchId}`, data);
        return response;
    },

    getMatched: async (params?: GetMatchedRequest): Promise<GetMatchedResponse> => {
        const response = await apiClient.get<GetMatchedResponse>('/matched', { params });
        return response.data;
    },
};

export default matchService;
