/* eslint-disable */
import apiClient from '../client';
import {
    CheckInRequest,
    EditParticipantRequest,
    GetParticipantsRequest,
} from '../requests';

import {
    CheckInResponse,
    EditParticipantResponse,
    GetParticipantsResponse,
} from '../responses';

const participantService = {
    checkIn: async (data: CheckInRequest): Promise<CheckInResponse> => {
        const response = await apiClient.post<CheckInResponse>(`/checkIn`, data);
        return response.data;
    },

    edit: async (data: EditParticipantRequest): Promise<EditParticipantResponse> => {
        const response = await apiClient.patch<EditParticipantResponse>('/participants', data);
        return response.data;
    },

    get: async (params?: GetParticipantsRequest): Promise<GetParticipantsResponse> => {
        const response = await apiClient.get<GetParticipantsResponse>('/participants', { params });
        return response.data;
    },

    detail: async (friendlyId?: string): Promise<GetParticipantsResponse> => {
        const response = await apiClient.get<GetParticipantsResponse>(`/participant`, { params: { friendly_id:friendlyId } });
        return response.data;
    }
};

export default participantService;
