/* eslint-disable */
import apiClient from '../client';
import {
    CreateTableRequest,
    GetTablesRequest,
    DeleteTableRequest,
    CreateTableResponse,
    GetTablesResponse,
    Table
} from '../types';

const tableService = {
    create: async (businessId: string, data: CreateTableRequest): Promise<CreateTableResponse> => {
        const response = await apiClient.post<CreateTableResponse>(`/businesses/${businessId}/tables`, data);
        return response.data;
    },

    get: async (params: GetTablesRequest): Promise<GetTablesResponse> => {
        const response = await apiClient.get<GetTablesResponse>(`/businesses/${params.businessId}/tables`, { params });
        return response.data;
    },

    delete: async (params: DeleteTableRequest): Promise<void> => {
        await apiClient.delete(`/businesses/${params.businessId}/tables/${params.id}`);
    },

    assign: async(): Promise<Table> => {
        const response = await apiClient.get(`/table`);
        return response.data;
    }
};

export default tableService;
