<template>
    <vs-button ref="btn" :dark="!transparent && (light || lightBlue || gray)" :danger="danger" :flat="danger" :transparent="transparent" :block="block" :size="size" @click="$emit('click')" :icon="circle" :circle="circle" :class="{'pty-shadow': shadow, 'no-disable': noDisable, 'zero-padding': noPadding, 'light-blue': lightBlue, 'gray': gray, 'animate': animate, 'extra-shadow': extraShadow}" :id="id">
        <slot></slot>
    </vs-button>
</template>
<script>
export default {
    name: 'PtyButton',
    props: {
        id: {
            type: String,
            default: ''
        },

        light: {
            type: Boolean,
            default: false
        },

        lightBlue: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },

        block: {
            type: Boolean,
            default: false
        },

        shadow: {
            type: Boolean,
            default: false
        },
        
        extraShadow: {
            type: Boolean,
            default: false
        },

        noDisable: {
            type: Boolean,
            default: false
        },

        noPadding: {
            type: Boolean,
            default: false
        },

        circle: {
            type: Boolean,
            default: false
        },

        transparent: {
            type: Boolean,
            default: false
        },

        gray: {
            type: Boolean,
            default: false
        },

        size: {
            type: String,
            default: 'xl'
        },

        danger: {
            type: Boolean,
            default: false
        },

        animate: {
            type: Boolean,
            default: false
        }
    },

    watch: {
        loading(n) {
            if(n){
                // add class to button
                this.$refs.btn.$el.classList.add('vs-button--pty-loading')
            } else {
                // remove class from button
                this.$refs.btn.$el.classList.remove('vs-button--pty-loading')
            }
        }
    }
}
</script>
<style>
.vs-button {
    font-family: 'Sofia Pro', sans-serif;
    font-weight: bold;
    border: solid 5px transparent;

    margin: 0!important;
}

.vs-button:not(.vs-button--circle){
    border-radius: 0.875rem!important;
}

.vs-button--circle {
    border-radius: 50%!important;
}

.vs-button.zero-padding .vs-button__content {
    padding: 0!important;
}
.vs-button--dark {
    color: rgba(var(--vs-primary));
}

.vs-button--pty-loading:not(.no-disable) .vs-button__content {
    opacity: 0.4;
}

.vs-button--pty-loading  {
  --border-angle: 0turn;
  --main-bg: conic-gradient(
      from var(--border-angle),
      #fff,
      #fff 5%,
      #fff 60%,
      #fff 95%
    );
  --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #30cce0, #248cc9 99%, transparent);
  background: var(--main-bg) padding-box, var(--gradient-border) border-box, var(--main-bg) border-box;
  background-position: center center;
  -webkit-animation: bg-spin 3s linear infinite;
          animation: bg-spin 3s linear infinite;
}
@-webkit-keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}
@keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

.vs-button.pty-shadow {
    box-shadow: 0px 4px 75px 0px rgba(164, 164, 164, 0.20);
}

.vs-button.extra-shadow {
    box-shadow: 0px 10px 35px 0px rgba(68, 188, 204, 0.60);
}

.vs-button.light-blue {
    background: #F4FAFF;
}

.vs-button.gray {
    background: var(--Linear, linear-gradient(52deg, #EDEDED -24.39%, #EEF0F2 104.23%));
}

.vs-button.animate {
    /* animate background white gradient */
    background: linear-gradient(60deg, rgba(var(--vs-primary), 0.7), rgba(var(--vs-primary), 1), rgba(var(--vs-primary), 0.7));
    background-size: 200% 100%;
    animation: animate 4s ease infinite alternate;
}

@keyframes animate {
    0% {
        background-position: 200% 0;
        box-shadow: 0px 10px 35px 0px rgba(68, 188, 204, 0.60);
    }
    100% {
        background-position: -200% 0;
        box-shadow: 50px 10px 35px 0px rgba(68, 188, 204, 1);
    }
}
</style>