import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
// @ts-ignore
import Vuesax from 'vuesax'

import 'vuesax/dist/vuesax.css' //Vuesax styles
Vue.use(Vuesax, {
  // options here
  colors: {
    primary:'#24B5C9',
    dark:'#ffffff',
  }
})

import 'boxicons/css/boxicons.min.css';

import './assets/fonts/stylesheet.css';
import i18n from './i18n'

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')

export default Vue;