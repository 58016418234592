/* eslint-disable */
import apiClient from '../client';
import { CreateReservationRequest, CreateReservationResponse,  } from '../types';

const reservationService = {
    create: async (data: CreateReservationRequest): Promise<CreateReservationResponse> => {
        const response = await apiClient.post<CreateReservationResponse>(`/events/${data.eventId}/reserve`, data);
        return response.data;
    },
};

export default reservationService;
